

.social-list {
  display: flex;
  li {
    margin: 0 @base-small-xx;
  }
  svg {
    width: 2.3em;
    height: 2.3em;
    display: inline-block;
    transition: color 0.3s;
  }

  // Section: About
  .about & {
    justify-content: center;
    li {
      &:nth-child(2),
      &:nth-child(3) {
        a {
          vertical-align: -1.45em;
        }
      }
    }
    svg {
      &.icon-google-doc {
        color: #4285f4;
        &:hover {
          color: darken(#4285f4, 9%);
        }
      }
      &.icon-linkedin {
        color: #0a66c2;
        &:hover {
          color: darken(#0a66c2, 5%);
        }
      }
      &.icon-github {
        color: #333;
        &:hover {
          color: #6e5494;
        }
      }
    }
  }

  // Section: Contact
  .contact & {
    li {
      &:first-child {
        margin-left: 0;
      }
    }
    a {
      color: tint(@color-gray-dark, 35%);
      &:hover {
        color: tint(@color-gray-dark, 65%);
      }
    }
  }
}