
.contact {
  input,
  textarea {
    background: transparent;
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    border-color: tint(@color-gray-dark, 25%);
    color: @color-gray;
    &:focus {
      border-color: @color-accent;
    }
  }
  label {
    color: white;
  }
}