// .project__item {}

.project__toggle {
  display: block;
  width: 100%;
  background-size: contain;
  background-size: 100%;
  position: relative;
  height: 1px;
  color: white;
  /* 16:10 Aspect Ratio */
  padding-top: 62.5%; // 800px/500px * 100%
  background-position: 50%;
  transition: background-size 0.6s ease-in-out;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: background-color 0.3s ease-in-out;
    background: rgba(42, 47, 54, 0.75);
    // background: rgba(220, 65, 51, .75);
  }

  &:hover,
  &:focus {
    background-size: 102%;
    outline: none;

    &:before {
      background: rgba(42, 47, 54, 0.9);
    }

    .project__toggle-text {
      bottom: 20px;
    }

    .project__icon {
      opacity: 1;
      bottom: @base-large - 5px;
    }
  }
}

.project__toggle-text {
  transition: bottom 0.6s;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: @base-large-x - 5px;
  // margin-bottom: @base-large-x - 15px;

  span {
    display: block;

    &:first-child {
      color: @color-black-20;
    }

    &:last-child {
      .t-bold;
      .t-large;
      line-height: 1.3em;
    }
  }
}

.project__icon {
  position: absolute;
  left: @base-large-x - 5px;
  bottom: @base-large;
  opacity: 0;
  transition: opacity 0.6s, bottom 0.6s;
  color: @color-accent;
}

.project__toggle-name {
  margin-top: @base-small-xx;
  .t-semibold;
}

.project__toggle-company {
  color: @t-detail;
  .t-small;
}

.project__content {
  img {
    width: 100%;
    margin-bottom: @element-margin / 2;
  }

  &.closed {
    display: none;
  }

  &.open {
    display: block;
  }
}

.project__close {
  position: absolute;
  right: @base-medium;
  top: 50%;
  transform: translateY(-50%);
  color: @color-accent;

  svg {
    height: 1.5em;
    width: 1.5em;
    display: block;
  }
}

.project__body-scroll {
  overflow-y: hidden;
}

// Modal
.project__header {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: @base-medium;
  right: @base-medium;
  padding-right: 1.5em;
  line-height: 1.2em;

  // color: darken(@color-gray-light, 20%);
  strong {
    font-size: 18px;
    display: block;
  }

  span {
    .t-detail;
    display: block;
    margin-top: 0.3em;
  }
}

.project__tech {
  margin-bottom: @element-margin;
  display: flex;
  flex-flow: row wrap;
  li {
    .t-small;
    margin-right: 0.5em;
    background-color: darken(@color-gray, 5%);
    color: white;
    padding: 0.2em 0.6em;
    margin-bottom: 0.6em;
    white-space: nowrap;
  }
}
