/* =============================================================================
// Site : Components : Layouts
// ===========================================================================*/

.section {
  .cf;
  padding-left: @section-padding-h-small;
  padding-right: @section-padding-h-small;

  @media @desktop-up {
    padding-left: @section-padding-h;
    padding-right: @section-padding-h;
  }
}

.group {
  max-width: 1360px;
  margin: 0 auto;

  &--doc {
    max-width: 816px;
  }
}