.collapsible__toggle {
  background: silver;
}

.collapsible {
  &.closed {
    .collapsible__content {
      display: none;
    }
  }

  &.open {
    .collapsible__content {
      display: block;
    }
  }
}