.component--scroll(@class: 'lx-scroll-projects',
  @body: {}

  ,
  @header: {}

  ,
  @footer: {}

  ,
  @header-height: 80px,
  @footer-height: 0px);

.scroll-projects {
  z-index: 200;

  .scroll-projects__header {
    .lx-scroll-projects__header;
    border-bottom: 1px solid tint(@color-gray-light, 20%);
    background: tint(@color-gray-light, 60%);
  }

  .scroll-projects__body {
    .lx-scroll-projects__body;
    .lx-scroll-projects__body--header-footer;
    padding: @base-medium;
  }

  // .scroll-projects__footer {
  //   .lx-scroll-projects__footer;
  // }
}