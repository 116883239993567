// .dropdown {}

// .dropdown__toggle {}

.dropdown__content {
  &.closed {
    display: none;
  }

  &.open {
    display: block;
  }
}