.t-detail {
  color: @t-detail;
}

.t-lead {
  #TYPE.create(@size: @t-large,
    @lh: @t-large-lh,
    @size-small: @t-break--large,
    @lh-small: @t-break--large-lh,
    @family: inherit,
    @with-p: yes);
}