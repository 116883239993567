/* =============================================================================
Base: Links
// =============================================================================  

Version:            1.0.0

Changes:            1.0.0 | ...

*/

a {
  background-color: transparent;
  text-decoration: none;
  color: @a-color;
  transition: color .3s;

  &:active,
  &:hover {
    outline-width: 0;
  }

  & when (@a-hover-visted--class-only =true) {
    #ELEMENT.links(); // Includes hover and visited
  }
}

.links {
  a {
    #ELEMENT.links();
  }
}