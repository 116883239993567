/* =============================================================================
Core Forms: General
============================================================================= */

// Description:     Add description

// Version:         1.0.1

// Contents:        - Core Styles
//                    - General
//                      - Reset
//                      - Elements
//                    - Classes
//


// =============================================================================
// Core Styles
// =============================================================================

// General Elements
// =============================================================================

// Form Reset
// =====================================

fieldset,
form,
label,
legend,
input {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

// Form Elements
// =====================================

form {
  margin: 0;
  padding: 0;
}

form,
fieldset {
  .form-item {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

label {
  #TYPE.create(@size: @t-small,
  @lh: 0.9em);
  color: @form-label-color;
  display: block;
  margin-bottom: @form-label-margin;
}

// Text Fields
input[type="text"],
input[type="password"],
input[type="number"],
input[type="url"],
input[type="search"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="datetime-local"],
input[type="time"],
input[type="email"],
input[type="month"],
input[type="week"],
input[type="color"] {
  #FORM.input-text(@height: @form-element-height,
  @bg-color: @form-element-bg-color,
  @font-color: @t-base-color,
  @font-size: @t-base,
  @border-width: 1px,
  @border-color: @form-brd-color,
  @border-radius: @form-element-radius,
  @width: 100%);
}

// Note:            Checkbox label should come right after checkbox input

input[type="checkbox"] when (@toggle-boolean-fancy =on) {
  //.mix-input-boolean--fancy(
  //  @size: @form-radio-checkbox-size,
  //  @icon-color: @form-radio-checkbox-icon-color,
  //  @brd-color: @form-brd-color,
  //  @brd-radius: @form-element-radius,
  //  @icon-name: "check",
  //  @icon-name-bg: "square-outline",
  //  @icon-nudge-h: 1,
  //  @icon-nudge-v: 0
  //);
}

input[type="checkbox"] when not (@toggle-boolean-fancy =on) {
  //.mix-input-boolean--plain();
}

input[type="radio"] when (@toggle-boolean-fancy =on) {
  //.mix-input-boolean--fancy(
  //  @size: @form-radio-checkbox-size,
  //  @icon-color: @form-radio-checkbox-icon-color,
  //  @brd-color: @form-brd-color,
  //  @brd-radius: 50%,
  //  @icon-name: "circle",
  //  @icon-name-bg: "circle-outline",
  //  @icon-nudge-h: 0,
  //  @icon-nudge-v: 0
  //);
}

input[type="radio"] when not (@toggle-boolean-fancy =on) {
  //.mix-input-boolean--plain();
}

input[type="file"] {
  //#TYPE.create-input(
  //  @height: @form-element-height,
  //  @line-height: 0,
  //  @bg-color: transparent,
  //  @font-color: @t-base-color,
  //  @font-size: @t-base,
  //  @border-width: 0,
  //  @border-color: transparent,
  //  @border-radius: @form-element-radius,
  //  @indent: -5px,
  //  @width: 100%,
  //  @pad-h: @form-element-padding-horizontal
  //);
}

// input[type="submit"],
// input[type="button"],
// input[type="reset"] {}
// input[type="color"] {}
// input[type="range"] {}

input,
textarea {
  transition: .2s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;
}

textarea {
  #FORM.input-text(@height: 150px,
  @bg-color: @form-element-bg-color,
  @font-color: @t-base-color,
  @font-size: @t-base,
  @border-width: 1px,
  @border-color: @form-brd-color,
  @border-radius: @form-element-radius,
  @width: 100%);
}

fieldset {
  &+& {
    margin-top: @form-item-margin-vertical;
  }
}

legend {
  //#TYPE.create(@t-large, @t-large-lh);
}

select {}


// Classes
// =============================================================================

// BLOCK: Form Item
// =====================================

.form-item {
  margin-bottom: @form-item-margin-vertical;

  // & + &,
  // legend + &,
  // p + &
  *+& {
    margin-top: @form-item-margin-vertical;
  }

  // MOD: Sizes
  // -------------------------------------

  &--large {
    max-width: @form-item--size-large;
  }

  &--med {
    max-width: @form-item--size-med;
  }

  &--small {
    max-width: @form-item--size-small;
  }

  &--small-x {
    max-width: @form-item--size-small-x;
  }

  // MOD: Types
  // -------------------------------------

  &--file when (@toggle-input-file-fancy =on) {
    >input[type="file"] {
      .hide-v();
    }

    // Make label to look like input
    >label {
      //.btn;
      cursor: pointer;
    }
  }

  // ELEMENT: Radio or Checkbox Type (Toggle)
  // -------------------------------------

  // Note:            These are inputs of toggle types. You can use a hook to
  //                  display them inline

  &__toggle {}
}

.select-list--icon when (@toggle-select-fancy =on) {
  //.mix-select(
  //  @icon-size: 140%
  //)
}

.form-item__description {
  margin-top: @form-description-margin;
  width: @form-description-width;
  #TYPE.create(@t-small, 1em);
}