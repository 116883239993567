// Core
// =============================================================================  
@import "_core";

// Site Specific
// =============================================================================  

@import "_site";

// Bottom of stylesheet
// =============================================================================  

@import "core/base/utilities";

.mix-bottom-stylesheet();

// * {
//   outline: 1px solid magenta !important;
//   background: bisque !important;
//   color: white !important;
// }

.scrollpoint__trigger {
  content: '';
  height: 1px;
  background: magenta;
  position: absolute;
  // top: auto;
  width: 100%;
  transition: top 0.25s ease;
  z-index: 9999;
}

#loader {
  background: black;
  width: 100%;
  height: 100vh;
  text-align: center;
  position: relative;

  img {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}