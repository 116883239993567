@header-transition-duration: .3s;
@header-transition-timing: ease;
@header-padding-v: 20px;
@header-padding-v-active: 10px;

.header {
  padding-top: @header-padding-v;
  padding-bottom: @header-padding-v;
  transition: all @header-transition-duration @header-transition-timing;
  background-color: @color-gray-dark;

  &:after {
    position: absolute;
    height: 10px;
    bottom: -10px;
    width: 100%;
    left: 0;
    content: "";
  }

  &__logo {
    transition: all @header-transition-duration @header-transition-timing;
    a {
      display: block;
      width: (423px / 5.5px);
      // .active & {
      //   width: (423px / 6.5px);
      // }
      color: lighten(@color-gray-dark, 15%);
      &:hover {
        color: lighten(@color-gray-dark, 20%);
      }
    }
    svg {
      display: block;
    }

  }

  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  transition: all @header-transition-duration @header-transition-timing;

  .menu-main {
    opacity: 0;
    transition: opacity @header-transition-duration @header-transition-timing;
  }

  &.active {
    padding-top: @header-padding-v-active;
    padding-bottom: @header-padding-v-active;
    background-color: @color-gray-dark-x;
    transition: all @header-transition-duration @header-transition-timing;

    &:after {
      background-image: linear-gradient(@color-black-a-20, transparent 40%);
    }

    .menu-main {
      opacity: 1;
      transition: all @header-transition-duration @header-transition-timing;
    }

    .header__logo {
      width: (423px / 6px);
      // line-height: 0; // remove space under svg
    }
  }
}