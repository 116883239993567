.footer {
  @section-padding-v();
  background: darken(@color-gray-dark, 2%);
  text-align: center;
  color: lighten(@color-gray-dark, 17%);

  svg {
    fill: lighten(@color-gray-dark, 6%);
    margin-bottom: -@base-small;
  }
}