/* =============================================================================
// Base: Normalize
// =============================================================================  

Version:            1.0.0

Changes:            1.0.0 | ...

*/


// Description:     This file is the top of the style sheet. It includes base 
//                  styles for defaults for elements. Including a variation
//                  of normalize and the defaults for the HTML element. This
//                  file is excluded from the wysiwyg render style sheet.
//
//                  Original normalize from "github.com/necolas/normalize.css". 
//                  File has been modified to remove duplication and things that 
//                  we set or were interfering with framework.

// body when not (@wysiwyg-stylesheet = true) { 
//   margin: 0;
// }
body {
  margin: 0;
}

article,
aside,
details,
/* 1 */
figcaption,
figure,
footer,
header,
main,
/* 2 */
menu,
nav,
section,
summary {
  /* 1 */
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
/* 1 */
[hidden] {
  display: none;
}

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}


/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
   ========================================================================== */

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct margin in IE 8.
 */

figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

// hr {
//   box-sizing: content-box; /* 1 */
//   height: 0; /* 1 */
//   overflow: visible; /* 2 */
// }

/* Forms
   ========================================================================== */

/**
 * Change font properties to `inherit` in all browsers (opinionated).
 */

button,
input,
select,
textarea {
  font: inherit;
}

/**
 * Restore the font weight unset by the previous rule.
 */

optgroup {
  font-weight: bold;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 * 2. Show the overflow in Edge, Firefox, and IE.
 */

button,
input,
/* 1 */
select {
  /* 2 */
  overflow: visible;
}

/**
 * Remove the margin in Safari.
 * 1. Remove the margin in Firefox and Safari.
 */

button,
input,
select,
textarea {
  /* 1 */
  margin: 0;
}

/**
 * Remove the inheritence of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritence of text transform in Firefox.
 */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Change the cursor in all browsers (opinionated).
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
}

/**
 * Restore the default cursor to disabled elements unset by the previous rule.
 */

[disabled] {
  cursor: default;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS.
 */

// button,
// html [type="button"], /* 1 */
// [type="reset"],
// [type="submit"] {
//   -webkit-appearance: button; /* 2 */
// }

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */

textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the odd appearance of search inputs in Chrome and Safari.
 */

[type="search"] {
  -webkit-appearance: textfield;
}

/**
 * Remove the inner padding and cancel buttons in Chrome on OS X and
 * Safari on OS X.
 */

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}