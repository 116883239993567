@section-padding-v: {
  padding-top: @base-large*3;
  padding-bottom: @base-large*3;

  @media @break-primary {
    padding-top: @base-large*2;
    padding-bottom: @base-large*2;
  }
}

@section-hero-bg: {
  background: linear-gradient(120deg, lighten(#e1f0fb, 2%) 50%, lighten(#e1f0fb, 0%) 50%);
}

// Modifiers
// =============================================================================

.section {
  &.intro {
    background-color: @color-gray-dark;
  }

  &.about {
    background: white url('/images/section--lines.svg') repeat-y center;
    @section-padding-v();
  }

  &.projects {
    background: #f8f8f8 url('/images/section--lines.svg') repeat-y center;
    @section-padding-v();
  }

  &.contact {
    background: @color-gray-dark;
    @section-padding-v();
    color: @color-gray;

    a {
      color: white;

      &:hover {
        color: white;
      }
    }
  }

  &.content {
    @section-padding-v();
  }
}


// Elements
// =============================================================================

.section__title {
  text-align: center;
  .h1;
  .h1__block;
}