// Version:         1.0.2

// Changes:         1.0.1 | Removed mix-opacity
//                  1.0.2 | Removed hooks
//                  1.0.2 | Fixed wrong mix-btn-size argument


// Create Classes
// =============================================================================

// Button Classes
// =====================================

.btn {
  .mix-btn(@height: @btn-height,
    @bg-color: @btn-bg,
    @font-color: @btn-txt-color,
    @brd-radius: @btn-border-radius,
    @brd-size: @btn-brd-width,
    @brd-color: @btn-brd-color,
    @font-size: @btn-txt-size,
    @hover--font-color: @btn-txt-color );
}

// Button Sizes
// =====================================

.mix-btn-size(@class-name: small,
  @height: @btn-small-height,
  @font-size: @btn-small-txt-size,
  @proportion: @btn-proportion);

.mix-btn-size(@class-name: large,
  @height: @btn-large-height,
  @font-size: @btn-large-txt-size,
  @proportion: @btn-proportion);

// Alternate Colors
// =====================================

// Add other colors, keep "alt" this is defualt.

/*[CHECK]-JS  |  Fix this indenting*/
// 

.mix-btn-color(@class-name: alt,
  @bg-color: @btn-alt-bg,
  @font-color: @btn-alt-txt-color,
  @brd-color: @btn-alt-brd-color);