// Text Sizes
// =====================================

// Added to bottom as override 

.mix-bottom-stylesheet() {

  // Defualt
  // -----------------------------------

  .t-base {
    #TYPE.create(@size: @t-base,
      @lh: @t-base-lh,
      @family: inherit,
      @with-p: yes);
  }

  // Sizes
  // -----------------------------------
  .t-large-xxx {
    #TYPE.create(@size: @t-large-xxx,
      @lh: @t-large-xxx-lh,
      @family: inherit,
      @with-p: yes);
  }

  .t-large-xx {
    #TYPE.create(@size: @t-large-xx,
      @lh: @t-large-xx-lh,
      @family: inherit,
      @with-p: yes);
  }

  .t-large-x {
    #TYPE.create(@size: @t-large-x,
      @lh: @t-large-x-lh,
      @family: inherit,
      @with-p: yes);
  }

  .t-large {
    #TYPE.create(@size: @t-large,
      @lh: @t-large-lh,
      @family: inherit,
      @with-p: yes);
  }

  .t-small {
    #TYPE.create(@size: @t-small,
      @lh: @t-small-lh,
      @family: inherit,
      @with-p: yes);
  }

  .t-small-x {
    #TYPE.create(@size: @t-small-x,
      @lh: @t-small-x-lh,
      @family: inherit,
      @with-p: yes);
  }

  // Line Height Modifiers
  // -----------------------------------

  .t-dense {
    line-height: @t-lh-dense;

    p {
      line-height: @t-lh-dense;
    }
  }

  .t-dense-x {
    line-height: @t-lh-dense-x;
  }

}