// =============================================================================
// Modules: Forms
// =============================================================================

// Mods
// =============================================================================

.section--dark {
  label {
    color: inherit;
  }

  input,
  textarea {
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    color: white;
    box-shadow: none;

    border-color: @color-white-a-15;

    &:focus {
      border-color: @color-white-a-50;
    }
  }

  // textarea {}
}