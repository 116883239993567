/* =============================================================================
Base: Lists
// =============================================================================  

Version:            1.0.0

Changes:            1.0.0 | ...

*/

ul {
  list-style: none;
}

ol {
  margin-bottom: @base-medium;
  list-style: decimal outside;

  li {
    margin-bottom: @base-small;
    margin-left: @t-base + 1px;
  }

  ol {
    margin: @base-small 0 0 @base-small;
    list-style-type: lower-alpha;

    ol {
      list-style-type: lower-roman;
    }
  }
}