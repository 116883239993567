.hero {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100vh;
  align-items: center;
  background: radial-gradient(lighten(@color-gray-dark, 4%), @color-gray-dark 65%);

  @media @break-primary {
    justify-content: center;
  }

  @media @mobile {
    justify-content: flex-start;
  }
}

.hero__arrow {
  color: @color-accent;
  display: inline-block;
  margin-top: @element-margin;

  &:hover {
    color: @color-accent--hover;
  }
}

.hero__text {
  flex-basis: 60%;
  width: 60%;
  padding: 110px 10% 40px 6%;
  background-image: url("/images/home-hero-texture.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  color: white;

  h1 {
    .h1;
    .t-bold;
    color: white;
  }

  strong {
    .h2;
    .t-normal;
    color: white;
    display: block;
  }

  @media (max-width: 1020px) {
    flex-basis: 55%;
    width: 55%;
    padding: 100px 5% 50px 0;
  }

  @media @break-primary {
    flex-basis: 80%;
    width: 80%;
    padding: 80px 5% 20px 0;
  }

  @media @mobile {
    flex-basis: 90%;
    width: 90%;
  }
}