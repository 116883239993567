// =============================================================================
// Module: Modals
// =============================================================================

.lx-modal {
  background: @modal-overlay-bg-color;
  z-index: @modal-z-index;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.lx-modal__panel {
  background-color: @modal-panel-background-color;
  position: relative;
  max-width: @modal-panel-max-width;
  // Vertical Margins & Centering
  height: (100%-@modal-panel-margin-v*2);
  transform: translateY(@modal-panel-margin-v);

  & when (ispixel(@modal-panel-max-width)) {
    // This is when modal goes from flexible horizontal margins, to fixed
    @modal-margin-breakpoint: (@modal-panel-max-width + @modal-panel-margin-h*2);
    // Horizontal Centering
    margin-left: auto;
    margin-right: auto;

    @media (max-width: @modal-margin-breakpoint) {
      margin-left: @modal-panel-margin-h;
      margin-right: @modal-panel-margin-h;
    }
  }

  & when not (ispixel(@modal-panel-max-width)) {
    margin-left: @modal-panel-margin-h;
    margin-right: @modal-panel-margin-h;
  }

  // Horizontal & Vertical Spacing Mobile
  @media @modal-breakpoint {
    height: (100%-@modal-panel-margin-v--mobile*2);
    transform: translateY(@modal-panel-margin-v--mobile);

    margin-left: @modal-panel-margin-h--mobile;
    margin-right: @modal-panel-margin-h--mobile;
  }

  &.open {}
  &.closed {}
}

.lx-modal {
  &.open {
    height: auto;
    visibility: visible;
    opacity: 1;
    transition: visibility .25s, opacity .25s;
  }
  &.closed {
    height: 0px;
    visibility: hidden;
    opacity: 0;
  }
}
