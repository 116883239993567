.pagination {
  display: flex;
  justify-content: center;
  margin-top: @element-margin;
}

.pagination__item {
  margin: 0 0.25em;
}

.pagination__button {
  .btn;
  .btn-small;
  color: @t-detail;

  &.active {
    background: darken(@btn-bg, 10%);
    color: @t-base-color;
  }
}