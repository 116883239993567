/* =============================================================================
// Init: Vars
// =============================================================================

# Global Variables

Version:            1.0.0

Changes:            1.0.0 | ...

*/
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/open-sans-v15-latin-300.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'), url('/fonts/open-sans-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/open-sans-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/open-sans-v15-latin-300.woff') format('woff'), /* Modern Browsers */ url('/fonts/open-sans-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/open-sans-v15-latin-300.svg#OpenSans') format('svg');
  /* Legacy iOS */
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/open-sans-v15-latin-600.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url('/fonts/open-sans-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/open-sans-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/open-sans-v15-latin-600.woff') format('woff'), /* Modern Browsers */ url('/fonts/open-sans-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/open-sans-v15-latin-600.svg#OpenSans') format('svg');
  /* Legacy iOS */
}
/* =============================================================================
// Base: Normalize
// =============================================================================  

Version:            1.0.0

Changes:            1.0.0 | ...

*/
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  /* 1 */
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
progress {
  vertical-align: baseline;
}
template,
[hidden] {
  display: none;
}
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}
dfn {
  font-style: italic;
}
mark {
  background-color: #ff0;
  color: #000;
}
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Grouping content
   ========================================================================== */
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}
/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
/* Forms
   ========================================================================== */
/**
 * Change font properties to `inherit` in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  font: inherit;
}
/**
 * Restore the font weight unset by the previous rule.
 */
optgroup {
  font-weight: bold;
}
/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 * 2. Show the overflow in Edge, Firefox, and IE.
 */
button,
input,
select {
  /* 2 */
  overflow: visible;
}
/**
 * Remove the margin in Safari.
 * 1. Remove the margin in Firefox and Safari.
 */
button,
input,
select,
textarea {
  /* 1 */
  margin: 0;
}
/**
 * Remove the inheritence of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritence of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}
/**
 * Change the cursor in all browsers (opinionated).
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
}
/**
 * Restore the default cursor to disabled elements unset by the previous rule.
 */
[disabled] {
  cursor: default;
}
/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS.
 */
/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}
/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}
/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the odd appearance of search inputs in Chrome and Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
}
/**
 * Remove the inner padding and cancel buttons in Chrome on OS X and
 * Safari on OS X.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/* =============================================================================
// Base: HTML,Body
// =============================================================================  

Version:            1.0.0

Changes:            1.0.0 | ...

*/
html {
  font-size: 16px;
  line-height: 1.5em;
  color: #09143b;
  font-family: 'Open Sans', 'Arial', sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}
@media screen and (max-width: 480px) {
  html {
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
/* =============================================================================
Base: Inputs
// =============================================================================

Version:            1.0.0

Changes:            1.0.0 | ...

*/
input[type="button"],
input[type="submit"],
input[type="reset"],
button,
select {
  text-transform: none;
  text-align: inherit;
  background-color: transparent;
  border: transparent;
  border-radius: 0;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
}
input[type="file"]:-webkit-file-upload-button {
  text-transform: none;
  text-align: inherit;
  background-color: transparent;
  border: transparent;
  border-radius: 0;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
}
/* =============================================================================
Core => Base => Typography
// ============================================================================= 
 
Version:            1.0.0
Changes:            1.0.0 | ...
*/
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
pre,
code,
p {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  border-width: 0;
  border-style: solid;
  font-weight: inherit;
}
p {
  margin-bottom: 0.75em;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
small {
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
  margin-bottom: 0.75em;
}
@media screen and (max-width: 768px) {
  small {
    font-size: 11.9px;
    font-size: 0.74375rem;
    line-height: 1.5em;
    margin-bottom: 0.75em;
  }
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
b,
strong {
  font-weight: bold;
}
/* =============================================================================
Base: Lists
// =============================================================================  

Version:            1.0.0

Changes:            1.0.0 | ...

*/
ul {
  list-style: none;
}
ol {
  margin-bottom: 23px;
  list-style: decimal outside;
}
ol li {
  margin-bottom: 15px;
  margin-left: 17px;
}
ol ol {
  margin: 15px 0 0 15px;
  list-style-type: lower-alpha;
}
ol ol ol {
  list-style-type: lower-roman;
}
/* =============================================================================
Base: Links
// =============================================================================  

Version:            1.0.0

Changes:            1.0.0 | ...

*/
a {
  background-color: transparent;
  text-decoration: none;
  color: #0da1ec;
  transition: color 0.3s;
}
a:active,
a:hover {
  outline-width: 0;
}
.links a {
  color: #0da1ec;
}
.links a:visited {
  color: purple;
}
.links a:hover {
  color: #ec330d;
}
/* =============================================================================
Core Forms: General
============================================================================= */
fieldset,
form,
label,
legend,
input {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}
form {
  margin: 0;
  padding: 0;
}
form .form-item:last-child,
fieldset .form-item:last-child {
  margin-bottom: 0;
}
label {
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 0.9em;
  color: #09143b;
  display: block;
  margin-bottom: 10px;
}
input[type="text"],
input[type="password"],
input[type="number"],
input[type="url"],
input[type="search"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="datetime-local"],
input[type="time"],
input[type="email"],
input[type="month"],
input[type="week"],
input[type="color"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
  height: 45px;
  background: white;
  border-width: 1px;
  border-style: solid;
  border-color: #d1d2d4;
  font-weight: normal;
  font-family: 'Open Sans', 'Arial', sans-serif;
  color: #09143b;
  text-indent: 6px;
  outline: 0;
  text-overflow: ellipsis;
  line-height: 1.3em;
  padding-left: 5px;
  padding-right: 5px;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="date"]:focus,
input[type="datetime-local"]:focus,
input[type="time"]:focus,
input[type="email"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="color"]:focus {
  border-color: #0da1ec;
}
input,
textarea {
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;
}
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
  height: 150px;
  background: white;
  border-width: 1px;
  border-style: solid;
  border-color: #d1d2d4;
  font-weight: normal;
  font-family: 'Open Sans', 'Arial', sans-serif;
  color: #09143b;
  text-indent: 6px;
  outline: 0;
  text-overflow: ellipsis;
  line-height: 1.3em;
  padding-left: 5px;
  padding-right: 5px;
}
textarea:focus {
  border-color: #0da1ec;
}
fieldset + fieldset {
  margin-top: 23px;
}
.form-item {
  margin-bottom: 23px;
}
* + .form-item {
  margin-top: 23px;
}
.form-item--large {
  max-width: 300px;
}
.form-item--med {
  max-width: 200px;
}
.form-item--small {
  max-width: 200px;
}
.form-item--small-x {
  max-width: 100px;
}
.form-item__description {
  margin-top: 10px;
  width: 40%;
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1em;
}
.lx-scroll {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.lx-scroll-projects__body {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}
.lx-scroll-projects__body--header {
  top: 80px;
}
.lx-scroll-projects__body--header-footer {
  top: 80px;
  bottom: 0px;
}
.lx-scroll-projects__body--footer {
  bottom: 0px;
}
.lx-scroll-projects__footer,
.lx-scroll-projects__header {
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  left: 0;
}
.lx-scroll-projects__header {
  height: 80px;
}
.lx-scroll-projects__footer {
  height: 0px;
  bottom: 0;
}
.scroll-projects {
  z-index: 200;
}
.scroll-projects .scroll-projects__header {
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  left: 0;
  height: 80px;
  border-bottom: 1px solid #e7eaed;
  background: #f3f4f6;
}
.scroll-projects .scroll-projects__body {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  top: 80px;
  bottom: 0px;
  padding: 23px;
}
.lx-modal {
  background: rgba(0, 0, 0, 0.7);
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.lx-modal__panel {
  background-color: white;
  position: relative;
  max-width: 1280px;
  height: 90%;
  transform: translateY(5%);
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1340px) {
  .lx-modal__panel {
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .lx-modal__panel {
    height: 100%;
    transform: translateY(0);
    margin-left: 0;
    margin-right: 0;
  }
}
.lx-modal.open {
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.25s, opacity 0.25s;
}
.lx-modal.closed {
  height: 0px;
  visibility: hidden;
  opacity: 0;
}
.list-bullet-plain,
.list-lines ul {
  margin-left: 2em;
}
.list-bullet-plain > li,
.list-bullet-plain ul > li,
.list-lines ul > li,
.list-lines ul ul > li {
  list-style: circle outside;
  margin-bottom: 0.75em;
  margin-top: 0.75em;
}
.list-bullet-plain ul,
.list-lines ul ul {
  margin-left: 2em;
}
.list-bullet-plain ul > li,
.list-lines ul ul > li {
  list-style-type: circle;
}
.list-bullet {
  list-style: none outside;
}
.list-bullet > li {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  padding-left: 1.25em;
  position: relative;
}
.list-bullet > li:before {
  display: block;
  position: absolute;
  background-color: transparent;
  top: -0.05em;
  left: 0;
  font-size: 0.8em;
  color: #0da1ec;
  font-family: "Arial";
}
.list-bullet > li:before {
  content: '\25A0';
}
.list-bullet ul {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  margin-left: 2em;
}
.list-bullet ul > li,
.list-bullet ul ul > li {
  list-style: circle outside;
  margin-bottom: 0.75em;
  margin-top: 0.75em;
}
.list-bullet ul ul {
  margin-left: 2em;
}
.list-bullet ul ul > li {
  list-style-type: circle;
}
.list-bullet ul > li {
  padding-left: 0;
}
.list-bullet ul > li:before {
  content: none;
}
.list-lines {
  border-color: #d1d2d4;
  border-style: solid;
  border-left-width: 0;
  border-right-width: 0;
}
.list-lines > li {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 0;
  margin-top: 0;
  border-bottom-width: 1px;
  border-color: inherit;
  border-style: inherit;
}
.list-lines ul {
  margin-left: 2em;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.list-lines > li:last-child {
  border-bottom-width: 0;
  border-style: inherit;
}
.list-plain {
  list-style: none outside;
  background-image: none;
}
.list-plain li {
  background-image: none;
  padding: 0;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.list-plain ul {
  margin-left: 0;
  list-style: none outside;
}
.list-plain ul li {
  padding-left: 0;
  background-image: none;
  list-style-type: none;
}
.list-plain ul ul li {
  list-style-type: none;
}
.wysiwyg h2 {
  font-weight: 600;
  margin-bottom: 0.6em;
  font-size: 22px;
}
.wysiwyg h3 {
  font-weight: 600;
  margin-bottom: 0.6em;
  font-size: 18px;
}
.t-detail {
  color: #848a9d;
}
.t-lead {
  font-family: inherit;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.5em;
}
.t-lead p {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.5em;
  margin-bottom: 0.75em;
}
@media screen and (max-width: 768px) {
  .t-lead {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1.5em;
  }
  .t-lead p {
    line-height: 1.5em;
    margin-bottom: 0.75em;
    font-size: 17px;
    font-size: 1.0625rem;
  }
}
.btn-disabled {
  cursor: default;
}
.btn-disabled:hover,
.btn-disabled:visited {
  background-color: #edeff1;
  border-color: #e5e7eb;
}
.btn-clear {
  background-color: transparent;
  background-image: none;
  border-width: 0;
  color: #0da1ec;
}
.btn-clear:hover,
.btn-clear:visited {
  background-color: transparent;
  background-image: none;
  border-width: 0;
}
.btn-clear:visited {
  color: #0da1ec;
}
.btn-clear:hover {
  color: #ec330d;
}
.btn-full {
  width: 100%;
  display: block;
  padding-left: 0;
  padding-right: 0;
}
.btn-inside {
  padding: 0;
}
.btn-inside input,
.btn-inside a,
.btn-inside button {
  display: block;
  padding: 13px 28.6px;
}
.btn-group-h {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.001px;
  white-space: nowrap;
}
.btn-group-h > .btn:hover,
.btn-group-h > .btn-inside:hover {
  position: relative;
  z-index: 2;
}
.btn-group-h > .btn:first-child,
.btn-group-h > .btn-inside:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group-h > .btn:last-child,
.btn-group-h > .btn-inside:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-h > .btn:not(:first-child):not(:last-child),
.btn-group-h > .btn-inside:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.btn-group-h > .btn:nth-child(n+2),
.btn-group-h > .btn-inside:nth-child(n+2) {
  margin-left: 0;
}
.btn-group-v > .btn,
.btn-group-v > .btn-inside {
  display: block;
  margin-top: -1px;
}
.btn-group-v > .btn:first-child,
.btn-group-v > .btn-inside:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group-v > .btn:last-child,
.btn-group-v > .btn-inside:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-v > .btn:not(:first-child):not(:last-child),
.btn-group-v > .btn-inside:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.btn-group-v > .btn:hover,
.btn-group-v > .btn-inside:hover {
  z-index: 2;
  position: relative;
}
.btn,
.btn-inside {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-style: solid;
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  border-radius: 0;
  padding: 13px 28.6px;
  color: inherit;
  line-height: 1.3em;
  letter-spacing: 0.02em;
  border-width: 0;
  border-color: #e5e7eb;
  background-color: #edeff1;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  transition: all 0.3s;
  font-weight: 600;
}
.btn:visited,
.btn-inside:visited {
  background-color: #edeff1;
  color: inherit;
  border-color: #e5e7eb;
}
.btn:hover,
.btn-inside:hover {
  background-color: #dce0e4;
  color: inherit;
  border-color: #d4d8dd;
}
.btn-small {
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  padding: 8px 17.6px;
}
.btn-large {
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 16px;
  font-size: 1rem;
  padding: 23px 50.6px;
}
/*[CHECK]-JS  |  Fix this indenting*/
.btn-alt {
  background-color: white;
  border-color: #0da1ec;
  border-width: 0;
  color: #000000;
}
.btn-alt:visited {
  background-color: white;
  color: #000000;
  border-color: #0da1ec;
}
.btn-alt:hover {
  background-color: #f0f0f0;
  color: #000000;
  border-color: #0b8dcf;
}
.grid-box {
  background-color: #e6e7e8;
}
.row-12 > div {
  float: left;
}
[data-grid*="columns: 12"] {
  *zoom: 1;
}
[data-grid*="columns: 12"]:before,
[data-grid*="columns: 12"]:after {
  content: "";
  display: table;
  flex-basis: 0;
  order: 1;
}
[data-grid*="columns: 12"]:after {
  clear: both;
}
[data-grid*="columns: 12"] > [data-grid-item] {
  min-height: 1px;
}
[data-grid*="columns: 12"][data-grid*="gutters-row:"] > [data-grid-item] {
  padding-top: 10px;
  padding-bottom: 10px;
}
[data-grid*="columns: 12"][data-grid*="gutters-row: fit"] {
  margin-top: -10px;
  margin-bottom: -10px;
}
@media screen and (min-width: 769px) {
  [data-grid*="columns: 12"] {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin-left: -10px;
    margin-right: -10px;
  }
  [data-grid*="columns: 12"] > [data-grid-item] {
    float: left;
    padding-left: 10px;
    padding-right: 10px;
  }
  [data-grid*="columns: 12"][data-grid*="align: middle"] {
    align-items: center;
  }
  [data-grid*="columns: 12"][data-grid*="align: end"] {
    align-items: flex-end;
  }
  [data-grid*="columns: 12"][data-grid*="align: start"] {
    align-items: flex-start;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width: 1"] {
    width: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width: 2"] {
    width: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width: 3"] {
    width: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width: 4"] {
    width: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width: 5"] {
    width: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width: 6"] {
    width: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width: 7"] {
    width: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width: 8"] {
    width: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width: 9"] {
    width: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width: 10"] {
    width: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width: 11"] {
    width: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width: 12"] {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset: 1"] {
    margin-left: 8.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset: 2"] {
    margin-left: 16.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset: 3"] {
    margin-left: 25%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset: 4"] {
    margin-left: 33.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset: 5"] {
    margin-left: 41.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset: 6"] {
    margin-left: 50%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset: 7"] {
    margin-left: 58.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset: 8"] {
    margin-left: 66.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset: 9"] {
    margin-left: 75%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset: 10"] {
    margin-left: 83.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset: 11"] {
    margin-left: 91.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset: 12"] {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1081px) {
  [data-grid*="columns: 12"] {
    margin-left: -15px;
    margin-right: -15px;
  }
  [data-grid*="columns: 12"] > [data-grid-item] {
    padding-left: 15px;
    padding-right: 15px;
  }
  [data-grid*="columns: 12"][data-grid*="gutters-row:"] > [data-grid-item] {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width-large: 1"] {
    width: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width-large: 2"] {
    width: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width-large: 3"] {
    width: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width-large: 4"] {
    width: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width-large: 5"] {
    width: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width-large: 6"] {
    width: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width-large: 7"] {
    width: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width-large: 8"] {
    width: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width-large: 9"] {
    width: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width-large: 10"] {
    width: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width-large: 11"] {
    width: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="width-large: 12"] {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset-large: 1"] {
    margin-left: 8.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset-large: 2"] {
    margin-left: 16.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset-large: 3"] {
    margin-left: 25%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset-large: 4"] {
    margin-left: 33.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset-large: 5"] {
    margin-left: 41.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset-large: 6"] {
    margin-left: 50%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset-large: 7"] {
    margin-left: 58.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset-large: 8"] {
    margin-left: 66.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset-large: 9"] {
    margin-left: 75%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset-large: 10"] {
    margin-left: 83.33333333%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset-large: 11"] {
    margin-left: 91.66666667%;
  }
  [data-grid*="columns: 12"] > [data-grid-item*="offset-large: 12"] {
    margin-left: 100%;
  }
}
body:before,
body:after {
  display: none;
}
@media screen {
  body:before {
    content: 'widescreen';
  }
}
body:before,
body:after {
  display: none;
}
@media screen and (max-width: 1350px) {
  body:before {
    content: 'large';
  }
}
body:before,
body:after {
  display: none;
}
@media screen and (max-width: 768px) {
  body:before {
    content: 'small';
  }
}
.t-gray {
  color: #9da7b3;
}
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-weight: 600;
}
.h1 {
  font-family: inherit;
  font-size: 42px;
  font-size: 2.625rem;
  line-height: 0.99em;
  margin-bottom: 0.495em;
}
@media screen and (max-width: 768px) {
  .h1 {
    font-size: 35.7px;
    font-size: 2.23125rem;
    line-height: 0.99em;
    margin-bottom: 0.495em;
  }
}
.h1__block {
  position: relative;
  z-index: 1;
}
.h1__block span {
  position: relative;
  z-index: 1;
  display: block;
  margin-bottom: -0.7em;
}
.h1__block:after {
  content: "";
  display: inline-block;
  width: 4em;
  height: 0.4em;
  background: #e2e5e8;
}
.contact .h1__block {
  color: white;
}
.contact .h1__block:after {
  background: #9da7b3;
  opacity: 0.2;
}
.h2 {
  font-family: inherit;
  font-size: 32px;
  font-size: 2rem;
  line-height: 0.99em;
  margin-bottom: 0.495em;
}
@media screen and (max-width: 768px) {
  .h2 {
    font-size: 27.2px;
    font-size: 1.7rem;
    line-height: 0.99em;
    margin-bottom: 0.495em;
  }
}
.h3 {
  font-family: inherit;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 0.99em;
  margin-bottom: 0.495em;
}
@media screen and (max-width: 768px) {
  .h3 {
    font-size: 20.4px;
    font-size: 1.275rem;
    line-height: 0.99em;
    margin-bottom: 0.495em;
  }
}
.h4 {
  font-family: inherit;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.5em;
  margin-bottom: 0.75em;
}
@media screen and (max-width: 768px) {
  .h4 {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1.5em;
    margin-bottom: 0.75em;
  }
}
.section--dark label {
  color: inherit;
}
.section--dark input,
.section--dark textarea {
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  color: white;
  box-shadow: none;
  border-color: rgba(255, 255, 255, 0.15);
}
.section--dark input:focus,
.section--dark textarea:focus {
  border-color: rgba(255, 255, 255, 0.5);
}
.btn-accent {
  background-color: transparent;
  border-color: #dc4133;
  border-width: 0;
  color: #dc4133;
}
.btn-accent:visited {
  background-color: transparent;
  color: #dc4133;
  border-color: #dc4133;
}
.btn-accent:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #dc4133;
  border-color: #cd3123;
}
.btn-accent {
  border-width: 1px;
}
.icon-svg--inline {
  display: inline-block;
  width: 2em;
  height: 1em;
}
.header {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #2a2f36;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  transition: all 0.3s ease;
}
.header:after {
  position: absolute;
  height: 10px;
  bottom: -10px;
  width: 100%;
  left: 0;
  content: "";
}
.header__logo {
  transition: all 0.3s ease;
}
.header__logo a {
  display: block;
  width: 76.90909091px;
  color: #4b5461;
}
.header__logo a:hover {
  color: #57616f;
}
.header__logo svg {
  display: block;
}
.header .menu-main {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.header.active {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #262a2f;
  transition: all 0.3s ease;
}
.header.active:after {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), transparent 40%);
}
.header.active .menu-main {
  opacity: 1;
  transition: all 0.3s ease;
}
.header.active .header__logo {
  width: 70.5px;
}
.footer {
  padding-top: 120px;
  padding-bottom: 120px;
  background: #262a30;
  text-align: center;
  color: #505967;
}
@media screen and (max-width: 768px) {
  .footer {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.footer svg {
  fill: #373e47;
  margin-bottom: -15px;
}
.scrollpoint--menu {
  position: relative;
  min-height: 50px;
}
.scrollpoint--menu .menu-scroll {
  z-index: 150;
  position: relative;
  top: auto;
}
.scrollpoint--menu.active .menu-scroll {
  position: fixed;
  z-index: 200;
  top: 0;
}
.menu-scroll,
.menu-scroll-mobile {
  display: flex;
  justify-content: space-around;
}
.menu-scroll a,
.menu-scroll-mobile a {
  padding: 0.7em 1em;
  padding-bottom: 0.9em;
  display: inline-block;
  position: relative;
  z-index: 1;
  color: #9da7b3;
  font-size: 18px;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 480px) {
  .menu-scroll a,
  .menu-scroll-mobile a {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}
.menu-scroll a:hover,
.menu-scroll-mobile a:hover {
  color: white;
}
.menu-scroll a:before,
.menu-scroll-mobile a:before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  bottom: 12px;
  height: 11px;
  background-color: transparent;
  transition: 0.3s ease-out;
  transition-property: background-color, border-color, box-shadow, height, right;
  left: 0.9em;
  right: 0.9em;
}
@media screen and (max-width: 480px) {
  .menu-scroll a:before,
  .menu-scroll-mobile a:before {
    left: 0.5em;
    right: 0.5em;
  }
}
.menu-scroll a.active,
.menu-scroll-mobile a.active {
  color: white;
}
.menu-scroll a.active::before,
.menu-scroll-mobile a.active::before {
  transition-property: background-color, border-color, box-shadow, height, right;
  background-color: rgba(157, 167, 179, 0.25);
}
@media (max-width: 870px) {
  .menu-scroll {
    display: none;
  }
}
.dropdown__content.closed {
  display: none;
}
.dropdown__content.open {
  display: block;
}
.collapsible__toggle {
  background: silver;
}
.collapsible.closed .collapsible__content {
  display: none;
}
.collapsible.open .collapsible__content {
  display: block;
}
.menu-mobile {
  color: white;
}
.menu-mobile__toggle {
  position: absolute;
  top: 0;
  right: 0;
  width: 65px;
  height: 70px;
  outline: none;
  transition: height 0.3s ease;
}
.header.active .menu-mobile__toggle {
  height: 50px;
}
.menu-mobile__icon {
  width: 30px;
  height: 30px;
  transform: translateY(-50%);
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
}
@media screen and (min-width: 1081px) {
  .menu-mobile__icon {
    right: 30px;
  }
}
.menu-mobile__icon span {
  position: absolute;
  height: 2px;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  transition: all cubic-bezier(0.25, 0.1, 0.28, 1.54) 0.32s;
}
.menu-mobile__icon span:nth-child(1) {
  top: 20%;
}
.menu-mobile__icon span:nth-child(2) {
  top: 50%;
}
.menu-mobile__icon span:nth-child(3) {
  top: 79%;
}
.menu-mobile__toggle.open .menu-mobile__icon span {
  background: #dc4133;
}
.menu-mobile__toggle.open .menu-mobile__icon span:nth-child(1) {
  transform: rotate(45deg);
  top: 49%;
}
.menu-mobile__toggle.open .menu-mobile__icon span:nth-child(2),
.menu-mobile__toggle.open .menu-mobile__icon span:nth-child(3) {
  transform: rotate(-45deg);
  top: 49%;
}
.menu-mobile__toggle.open .menu-mobile__icon span:nth-child(3) {
  display: none;
}
.menu-mobile__content {
  margin-top: 6px;
  background-color: #30353c;
  position: absolute;
  left: 0;
  width: 100%;
  top: 70px;
  z-index: 101;
}
.menu-mobile__content.closed {
  display: none;
}
.menu-mobile__content.open {
  display: block;
}
.header.active .menu-mobile__content {
  top: 50px;
}
@media (min-width: 870px) {
  .menu-mobile {
    display: none;
  }
}
/* =============================================================================
// Site : Components : Layouts
// ===========================================================================*/
.section {
  *zoom: 1;
  padding-left: 20px;
  padding-right: 20px;
}
.section:before,
.section:after {
  content: "";
  display: table;
  flex-basis: 0;
  order: 1;
}
.section:after {
  clear: both;
}
@media screen and (min-width: 1081px) {
  .section {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.group {
  max-width: 1360px;
  margin: 0 auto;
}
.group--doc {
  max-width: 816px;
}
.doc {
  background-color: white;
  box-shadow: 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  margin-top: 0.6em;
  margin-bottom: 2em;
  min-height: 1056px;
  padding: 1em 1em;
}
@media screen and (min-width: 481px) {
  .doc {
    padding: 43px 4px 43px 47px;
  }
}
@media screen and (min-width: 1081px) {
  .doc {
    margin-top: 1.6em;
    padding: 86px 86px 86px 96px;
  }
}
.doc-header span {
  display: block;
}
.doc-header__logo {
  margin-top: 0.4em;
  width: 84.6px;
  height: 39.6px;
  float: left;
  margin-right: 0.5em;
}
.doc-header__name {
  font-weight: 600;
  color: #09143b;
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.15em;
}
.doc-header__title {
  color: #09143b;
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1em;
}
html {
  background-color: #f8f9fa;
  font-weight: 300;
}
.hero {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100vh;
  align-items: center;
  background: radial-gradient(#333941, #2a2f36 65%);
}
@media screen and (max-width: 768px) {
  .hero {
    justify-content: center;
  }
}
@media screen and (max-width: 480px) {
  .hero {
    justify-content: flex-start;
  }
}
.hero__arrow {
  color: #dc4133;
  display: inline-block;
  margin-top: 23px;
}
.hero__arrow:hover {
  color: #e77a70;
}
.hero__text {
  flex-basis: 60%;
  width: 60%;
  padding: 110px 10% 40px 6%;
  background-image: url("/images/home-hero-texture.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  color: white;
}
.hero__text h1 {
  font-weight: 600;
  font-family: inherit;
  font-size: 42px;
  font-size: 2.625rem;
  line-height: 0.99em;
  margin-bottom: 0.495em;
  font-weight: bold;
  color: white;
}
@media screen and (max-width: 768px) {
  .hero__text h1 {
    font-size: 35.7px;
    font-size: 2.23125rem;
    line-height: 0.99em;
    margin-bottom: 0.495em;
  }
}
.hero__text strong {
  font-weight: 600;
  font-family: inherit;
  font-size: 32px;
  font-size: 2rem;
  line-height: 0.99em;
  margin-bottom: 0.495em;
  font-weight: normal;
  color: white;
  display: block;
}
@media screen and (max-width: 768px) {
  .hero__text strong {
    font-size: 27.2px;
    font-size: 1.7rem;
    line-height: 0.99em;
    margin-bottom: 0.495em;
  }
}
@media (max-width: 1020px) {
  .hero__text {
    flex-basis: 55%;
    width: 55%;
    padding: 100px 5% 50px 0;
  }
}
@media screen and (max-width: 768px) {
  .hero__text {
    flex-basis: 80%;
    width: 80%;
    padding: 80px 5% 20px 0;
  }
}
@media screen and (max-width: 480px) {
  .hero__text {
    flex-basis: 90%;
    width: 90%;
  }
}
.section.intro {
  background-color: #2a2f36;
}
.section.about {
  background: white url('/images/section--lines.svg') repeat-y center;
  padding-top: 120px;
  padding-bottom: 120px;
}
@media screen and (max-width: 768px) {
  .section.about {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.section.projects {
  background: #f8f8f8 url('/images/section--lines.svg') repeat-y center;
  padding-top: 120px;
  padding-bottom: 120px;
}
@media screen and (max-width: 768px) {
  .section.projects {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.section.contact {
  background: #2a2f36;
  padding-top: 120px;
  padding-bottom: 120px;
  color: #9da7b3;
}
@media screen and (max-width: 768px) {
  .section.contact {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.section.contact a {
  color: white;
}
.section.contact a:hover {
  color: white;
}
.section.content {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media screen and (max-width: 768px) {
  .section.content {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.section__title {
  text-align: center;
  font-weight: 600;
  font-family: inherit;
  font-size: 42px;
  font-size: 2.625rem;
  line-height: 0.99em;
  margin-bottom: 0.495em;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .section__title {
    font-size: 35.7px;
    font-size: 2.23125rem;
    line-height: 0.99em;
    margin-bottom: 0.495em;
  }
}
.section__title span {
  position: relative;
  z-index: 1;
  display: block;
  margin-bottom: -0.7em;
}
.section__title:after {
  content: "";
  display: inline-block;
  width: 4em;
  height: 0.4em;
  background: #e2e5e8;
}
.contact .section__title {
  color: white;
}
.contact .section__title:after {
  background: #9da7b3;
  opacity: 0.2;
}
.project__toggle {
  display: block;
  width: 100%;
  background-size: contain;
  background-size: 100%;
  position: relative;
  height: 1px;
  color: white;
  /* 16:10 Aspect Ratio */
  padding-top: 62.5%;
  background-position: 50%;
  transition: background-size 0.6s ease-in-out;
}
.project__toggle:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: background-color 0.3s ease-in-out;
  background: rgba(42, 47, 54, 0.75);
}
.project__toggle:hover,
.project__toggle:focus {
  background-size: 102%;
  outline: none;
}
.project__toggle:hover:before,
.project__toggle:focus:before {
  background: rgba(42, 47, 54, 0.9);
}
.project__toggle:hover .project__toggle-text,
.project__toggle:focus .project__toggle-text {
  bottom: 20px;
}
.project__toggle:hover .project__icon,
.project__toggle:focus .project__icon {
  opacity: 1;
  bottom: 35px;
}
.project__toggle-text {
  transition: bottom 0.6s;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 55px;
}
.project__toggle-text span {
  display: block;
}
.project__toggle-text span:first-child {
  color: #d1d2d4;
}
.project__toggle-text span:last-child {
  font-weight: bold;
  font-family: inherit;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.5em;
  line-height: 1.3em;
}
.project__toggle-text span:last-child p {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.5em;
  margin-bottom: 0.75em;
}
.project__icon {
  position: absolute;
  left: 55px;
  bottom: 40px;
  opacity: 0;
  transition: opacity 0.6s, bottom 0.6s;
  color: #dc4133;
}
.project__toggle-name {
  margin-top: 5px;
  font-weight: 600;
}
.project__toggle-company {
  color: #848a9d;
  font-family: inherit;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
}
.project__toggle-company p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
  margin-bottom: 0.75em;
}
.project__content img {
  width: 100%;
  margin-bottom: 11.5px;
}
.project__content.closed {
  display: none;
}
.project__content.open {
  display: block;
}
.project__close {
  position: absolute;
  right: 23px;
  top: 50%;
  transform: translateY(-50%);
  color: #dc4133;
}
.project__close svg {
  height: 1.5em;
  width: 1.5em;
  display: block;
}
.project__body-scroll {
  overflow-y: hidden;
}
.project__header {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 23px;
  right: 23px;
  padding-right: 1.5em;
  line-height: 1.2em;
}
.project__header strong {
  font-size: 18px;
  display: block;
}
.project__header span {
  color: #848a9d;
  display: block;
  margin-top: 0.3em;
}
.project__tech {
  margin-bottom: 23px;
  display: flex;
  flex-flow: row wrap;
}
.project__tech li {
  font-family: inherit;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
  margin-right: 0.5em;
  background-color: #8f9aa8;
  color: white;
  padding: 0.2em 0.6em;
  margin-bottom: 0.6em;
  white-space: nowrap;
}
.project__tech li p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
  margin-bottom: 0.75em;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 23px;
}
.pagination__item {
  margin: 0 0.25em;
}
.pagination__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-style: solid;
  border-radius: 0;
  padding: 13px 28.6px;
  color: inherit;
  line-height: 1.3em;
  letter-spacing: 0.02em;
  border-width: 0;
  border-color: #e5e7eb;
  background-color: #edeff1;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  transition: all 0.3s;
  font-weight: 600;
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  padding: 8px 17.6px;
  color: #848a9d;
}
.pagination__button:visited {
  background-color: #edeff1;
  color: inherit;
  border-color: #e5e7eb;
}
.pagination__button:hover {
  background-color: #dce0e4;
  color: inherit;
  border-color: #d4d8dd;
}
.pagination__button.active {
  background: #d1d5db;
  color: #09143b;
}
.icon path {
  fill: inherit;
}
.btn-icon {
  position: relative;
  padding-left: 3.75em;
  padding-right: 1.25em;
}
.btn-icon .icon {
  width: 1.75em;
  height: 1.75em;
  position: absolute;
  top: 50%;
  left: 1.25em;
  transform: translateY(-50%);
}
.btn-icon-right {
  position: relative;
  padding-right: 3.75em;
  padding-left: 1.25em;
}
.btn-icon-right .icon {
  width: 1.75em;
  height: 1.75em;
  position: absolute;
  top: 50%;
  right: 1.25em;
  transform: translateY(-50%);
}
.social-list {
  display: flex;
}
.social-list li {
  margin: 0 5px;
}
.social-list svg {
  width: 2.3em;
  height: 2.3em;
  display: inline-block;
  transition: color 0.3s;
}
.about .social-list {
  justify-content: center;
}
.about .social-list li:nth-child(2) a,
.about .social-list li:nth-child(3) a {
  vertical-align: -1.45em;
}
.about .social-list svg.icon-google-doc {
  color: #4285f4;
}
.about .social-list svg.icon-google-doc:hover {
  color: #1769f1;
}
.about .social-list svg.icon-linkedin {
  color: #0a66c2;
}
.about .social-list svg.icon-linkedin:hover {
  color: #0959aa;
}
.about .social-list svg.icon-github {
  color: #333;
}
.about .social-list svg.icon-github:hover {
  color: #6e5494;
}
.contact .social-list li:first-child {
  margin-left: 0;
}
.contact .social-list a {
  color: #75787c;
}
.contact .social-list a:hover {
  color: #b4b6b9;
}
.contact input,
.contact textarea {
  background: transparent;
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  border-color: #5f6368;
  color: #9da7b3;
}
.contact input:focus,
.contact textarea:focus {
  border-color: #dc4133;
}
.contact label {
  color: white;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader__image {
  width: 4em;
  height: 4em;
}
/* =============================================================================
// Base: Utilities
// =============================================================================  

Version:            1.0.0

Changes:            1.0.0 | ...

*/
.cf {
  *zoom: 1;
}
.cf:before,
.cf:after {
  content: "";
  display: table;
  flex-basis: 0;
  order: 1;
}
.cf:after {
  clear: both;
}
.hide-v {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.hide-v.focusable:active,
.hide-v.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
.unhide-text {
  text-indent: 0;
  white-space: normal;
}
.hidden-abs {
  position: absolute;
  left: -10000px;
  top: -10000px;
}
.hidden-abs-no {
  position: static;
  left: auto;
  top: auto;
}
.no-select {
  user-select: none;
  pointer-events: none;
  cursor: pointer;
}
.no-scroll-y {
  overflow-y: hidden;
}
.no-scroll-x {
  overflow-x: hidden;
}
.t-light {
  font-weight: 300;
}
.t-normal {
  font-weight: normal;
}
.t-semibold {
  font-weight: 600;
}
.t-italic {
  font-style: italic;
}
.t-bold {
  font-weight: bold;
}
.t-center {
  text-align: center;
}
.t-left {
  text-align: left;
}
.t-right {
  text-align: right;
}
.t-upper {
  text-transform: uppercase;
}
.t-lower {
  text-transform: lowercase;
}
.t-capitalize {
  text-transform: capitalize;
}
.breakwords {
  -ms-word-break: normal;
  word-break: normal;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.breakwords-all {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.m-no {
  margin: 0;
}
.m-right-no {
  margin-right: 0;
}
.m-left-no {
  margin-left: 0;
}
.m-top-no {
  margin-top: 0;
}
.m-btm-no {
  margin-bottom: 0;
}
.m-sides-no {
  margin-left: 0;
  margin-right: 0;
}
.m-ends-no {
  margin-top: 0;
  margin-bottom: 0;
}
.m-all {
  margin: 23px;
}
.m-right {
  margin-right: 23px;
}
.m-left {
  margin-left: 23px;
}
.m-top {
  margin-top: 23px;
}
.m-btm {
  margin-bottom: 23px;
}
.m-sides {
  margin-left: 23px;
  margin-right: 23px;
}
.m-ends {
  margin-top: 23px;
  margin-bottom: 23px;
}
.t-base {
  font-family: inherit;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
}
.t-base p {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  margin-bottom: 0.75em;
}
.t-large-xxx {
  font-family: inherit;
  font-size: 42px;
  font-size: 2.625rem;
  line-height: 0.99em;
}
.t-large-xxx p {
  font-size: 42px;
  font-size: 2.625rem;
  line-height: 0.99em;
  margin-bottom: 0.495em;
}
.t-large-xx {
  font-family: inherit;
  font-size: 32px;
  font-size: 2rem;
  line-height: 0.99em;
}
.t-large-xx p {
  font-size: 32px;
  font-size: 2rem;
  line-height: 0.99em;
  margin-bottom: 0.495em;
}
.t-large-x {
  font-family: inherit;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 0.99em;
}
.t-large-x p {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 0.99em;
  margin-bottom: 0.495em;
}
.t-large {
  font-family: inherit;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.5em;
}
.t-large p {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.5em;
  margin-bottom: 0.75em;
}
.t-small {
  font-family: inherit;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
}
.t-small p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
  margin-bottom: 0.75em;
}
.t-small-x {
  font-family: inherit;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.5em;
}
.t-small-x p {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.5em;
  margin-bottom: 0.75em;
}
.t-dense {
  line-height: 1.2em;
}
.t-dense p {
  line-height: 1.2em;
}
.t-dense-x {
  line-height: 0.8em;
}
.scrollpoint__trigger {
  content: '';
  height: 1px;
  background: magenta;
  position: absolute;
  width: 100%;
  transition: top 0.25s ease;
  z-index: 9999;
}
#loader {
  background: black;
  width: 100%;
  height: 100vh;
  text-align: center;
  position: relative;
}
#loader img {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
