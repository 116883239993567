.wysiwyg {
  h2 {
    font-weight: 600;
    margin-bottom: 0.6em;
    font-size: 22px;
  }

  h3 {
    font-weight: 600;
    margin-bottom: 0.6em;
    font-size: 18px;
  }

  ul {}
}