.icon {
  path {
    fill: inherit;
  }
}

.btn-icon {
  position: relative;
  padding-left: 1.75em + 1.25em + 0.75em;
  padding-right: 1.25em;

  .icon {
    width: 1.75em;
    height: 1.75em;
    position: absolute;
    top: 50%;
    left: 1.25em;
    transform: translateY(-50%);
  }
}

.btn-icon-right {
  position: relative;
  padding-right: 1.75em + 1.25em + 0.75em;
  padding-left: 1.25em;

  .icon {
    width: 1.75em;
    height: 1.75em;
    position: absolute;
    top: 50%;
    right: 1.25em;
    transform: translateY(-50%);
  }
}