.h1,
.h2,
.h3,
.h4,
.h5 {
  .t-semibold;
}

.h1 {
  #TYPE.create(@size: @t-large-xxx,
    @lh: @t-large-xxx-lh,
    @size-small: @t-break--large-xxx,
    @lh-small: @t-break--large-xxx-lh,
    @family: inherit,
    @margin: true);
}

.h1__block {
  position: relative;
  z-index: 1;

  span {
    position: relative;
    z-index: 1;
    display: block;
    margin-bottom: -0.7em;
  }

  &:after {
    content: "";
    display: inline-block;
    width: 4em;
    height: .4em;
    background: @color-gray-light;
  }

  .contact & {
    color: white;

    &:after {
      background: @color-gray;
      opacity: 0.2;
    }
  }
}

.h2 {
  #TYPE.create(@size: @t-large-xx,
    @lh: @t-large-xx-lh,
    @size-small: @t-break--large-xx,
    @lh-small: @t-break--large-xx-lh,
    @family: inherit,
    @margin: true);
}

.h3 {
  #TYPE.create(@size: @t-large-x,
    @lh: @t-large-x-lh,
    @size-small: @t-break--large-x,
    @lh-small: @t-break--large-x-lh,
    @family: inherit,
    @margin: true);
}

.h4 {
  #TYPE.create(@size: @t-large,
    @lh: @t-large-lh,
    @size-small: @t-break--large,
    @lh-small: @t-break--large-lh,
    @family: inherit,
    @margin: true);
}