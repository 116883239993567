// =============================================================================
// Menu Scroll
// =============================================================================

@menu-scroll--a: {
  padding: 0.7em 1em;
  padding-bottom: 0.9em;
  display: inline-block;
  position: relative;
  z-index: 1;
  color: @color-gray;
  font-size: 18px;
  letter-spacing: 0.05em;

  @media @mobile {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  &:hover {
    color: white;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    bottom: 12px;
    height: 11px;
    background-color: transparent;
    transition: 0.3s ease-out;
    transition-property: background-color, border-color, box-shadow, height, right;
    left: 0.9em;
    right: 0.9em;

    @media @mobile {
      left: 0.5em;
      right: 0.5em;
    }
  }

  &.active {
    color: white;

    &::before {
      transition-property: background-color, border-color, box-shadow, height, right;
      background-color: rgba(157, 167, 179, 0.25);
    }
  }
}

.scrollpoint--menu {
  position: relative;
  min-height: 50px;

  .menu-scroll {
    z-index: 150;
    position: relative;
    top: auto;
  }

  &.active {
    .menu-scroll {
      position: fixed;
      z-index: 200;
      top: 0;
    }
  }
}

// Shared
// =============================================================================

.menu-scroll,
.menu-scroll-mobile {
  display: flex;
  justify-content: space-around;

  a {
    @menu-scroll--a()
  }
}


// Responsive
// =============================================================================

.menu-scroll {
  @media (max-width: 870px) {
    display: none;
  }
}